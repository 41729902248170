import { specialConnectors } from "./connectorsConfiguration";

// Google Sheets Query
const buildGoogleSheetsQuery = (data) => ({
  document_url: data.document_url || "",
  sheet_name: data.sheet_name || "",
  sheet_range: data.sheet_range || "",
});

// JSON/CSV/XML Query
const buildJSONQuery = (data) => ({
  url: data.url || "",
  json_path: data.json_path || "",
  request: data.request || "",
  headers: data.headers || "",
  type: data.type || "",
});

// Instagram Query
const buildInstagramQuery = (data) => {
  let query = {};
  switch (data.queryType) {
    case "profile_info":
      query = {};
      break;
    case "profile_posts":
      query = {};
      break;
    case "post_search":
      query = {
        post_search_type: data.post_search_type || "recent_media",
        num_posts_by_hashtag: data.num_posts_by_hashtag || 50,
      };
      break;
    default:
      query = {
        queryType: data.queryType || "",
      };
      break;
  }

  return query;
};

// Instagram Query
const buildGoogleTrendsQuery = (data) => {
  let query = {};
  switch (data.queryType) {
    case "interest_over_time":
      query = {
        search_term: data.search_term || "",
        country: data.country || "",
        gprop: data.gprop || "",
        category: data.category || "",
      };
      break;
    case "interest_by_region":
      query = {
        search_term: data.search_term || "",
        country: data.country || "",
        gprop: data.gprop || "",
        category: data.category || "",
      };
      break;
    case "related_queries":
      query = {
        search_term: data.search_term || "",
        country: data.country || "",
        gprop: data.gprop || "",
        category: data.category || "",
        result_type: data.result_type || "",
      };
      break;
    case "related_topics":
      query = {
        search_term: data.search_term || "",
        country: data.country || "",
        gprop: data.gprop || "",
        category: data.category || "",
        result_type: data.result_type || "",
      };
      break;
    case "related_suggestions":
      query = {
        search_term: data.search_term || "",
      };
      break;
    case "daily_search_trends":
      query = {
        country: data.country || "",
      };
      break;
    case "top_charts":
      query = {
        country: data.country || "",
        year: data.year || "",
      };
      break;
    default:
      query = {};
      break;
  }

  return query;
};

// Función para obtener los accountsViews
const getAccountsViewsForConnector = (connectorType, data) => {
  if (connectorType.startsWith("blend")) {
    return data.accounts;
  }

  switch (connectorType) {
    case "instagram_public_data":
      return data.hashtags
        ? [{ hashtags: data.hashtags.split(", ").join(", ") }]
        : [{ usernames: data.usernames.split(", ").join(", ") }];
    case "google_sheets":
      return [];
    case "facebook_public_data":
      return data.page_ids ? [{ page_ids: data.page_ids.split(", ").join(", ") }] : [];
    case "dcm" || "dbm":
      return data.accounts.map((account) => ({
        id: account.id,
        name: account.name,
        email: account.email,
        connection_id: account.connection_id,
        advertiser_id: account.advertiser_id,
        profile_id: account.profile_id,
      }));
    case "bigquery":
      return data.accounts.map((account) => ({
        project_id: account.project_id,
        account_name: account.name,
        connection_id: account.connection_id,
      }));
    case "hubspot_basic":
      return data.accounts.map((account) => ({
        ...account,
        connection_id: account.connection_id,
      }));

    default:
      return data.accounts.map((account) => ({
        connection_id: account.connection_id,
        id: account.id,
        email: account.email,
        profile_id: account.profile_id,
        name: account.name,
      }));
  }
};

// Obtener los otherParameters específicos del conector
const getOtherParametersForConnector = (connectorType, data) => {
  switch (connectorType) {
    case "google_sheets":
      return buildGoogleSheetsQuery(data);
    case "instagram_public_data":
      return buildInstagramQuery(data);
    case "google_trends":
      return buildGoogleTrendsQuery(data);
    case "json_csv_xml":
      return buildJSONQuery(data);
    default:
      return {};
  }
};

const buildRefreshWithUserAccount = (entryConnector, accounts, connectors) => {
  const isSpecialConnector = specialConnectors.find((connector) => connector.key === entryConnector);
  const isBlendConnector = entryConnector && entryConnector.startsWith("blend");
  if (isSpecialConnector) {
    const userAccount =
      connectors && entryConnector && connectors[entryConnector]
        ? connectors[entryConnector].map((connection) => ({
            id: connection.id,
          }))
        : [];
    return userAccount;
  } else if (isBlendConnector) {
    return accounts.map((account) => ({
      id: account.id,
      datasource_id: account.datasource_id,
    }));
  } else {
    return Array.from(new Set(accounts.map((account) => account.connection_id))).map((id) => ({ id }));
  }
};

const connectorsWithoutDates = [
  "google_sheets",
  "instagram_public_data",
  "json_csv_xml",
  "database",
  "mongodb",
  "facebook_public_data",
];
const shouldIncludeDates = (connectorType) => {
  return !connectorsWithoutDates.includes(connectorType);
};

export const buildQueryRequest = (
  selectedQuery,
  connectorType,
  connectionName,
  requestData,
  dates,
  accounts,
  metrics,
  dynamicFormValues,
  connectors,
  dimensions,
  pivotDimensions,
  limits
) => {
  const includeDates = shouldIncludeDates(connectorType);
  const parseIfString = (value) => {
    try {
      return typeof value === "string" ? JSON.parse(value) : value;
    } catch (e) {
      return value;
    }
  };
  return {
    queryUUID: selectedQuery ? selectedQuery.queryUUID : "",
    dataSource: connectorType,
    dateRangeType: includeDates ? dates.date_range_type : "",
    startDate: includeDates ? dates.start_date : "",
    endDate: includeDates ? dates.end_date : "",
    accountsViews: parseIfString(getAccountsViewsForConnector(connectorType, requestData)),
    metrics: parseIfString(metrics) || [],
    dimensions: parseIfString(dimensions) || [],
    pivotDimensions: parseIfString(pivotDimensions) || [],
    filters: parseIfString(requestData.filters) || [],
    maxRows: limits.limitRows || 0,
    maxPivotCategories: limits.limitCols || 0,
    specialSettings: parseIfString(requestData.options) || {},
    otherParameters: parseIfString(getOtherParametersForConnector(connectorType, dynamicFormValues)) || {},
    reportType: dynamicFormValues.queryType || "",
    refreshWithUserAccount: buildRefreshWithUserAccount(connectorType, requestData.accounts, connectors) || [],
    sort_by: parseIfString(requestData.sort_by) || [],
    sort_dims_by: parseIfString(requestData.sort_dims_by) || [],
    options: parseIfString(requestData.options) || {},
    blendName: connectorType.startsWith("blend") ? connectionName : "",
    compareTo:
      parseIfString({
        to: requestData.compare_dates?.to,
        start_date: requestData.compare_dates?.start_date,
        end_date: requestData.compare_dates?.end_date,
      }) || "",
    comparisonValueType: parseIfString(requestData.compare_dates?.type) || "",
  };
};
