import * as React from "react";
import {
  Select,
  Button,
  makeStyles,
  tokens,
  Caption1,
  Caption1Strong,
  Body1,
  TagPicker,
  TagPickerList,
  TagPickerInput,
  TagPickerControl,
  TagPickerOption,
  TagPickerGroup,
  Label,
  Field,
} from "@fluentui/react-components";
import { Tag } from "@fluentui/react-components";
import { Delete16Filled } from "@fluentui/react-icons";
import DateRangeDialog from "./DateRangeDialog";
import SortMetricsDialog from "./SortMetricsDialog";
import SortDimsDialog from "./SortDimsDialog";
import FiltersDialog from "./FiltersDialog";
import { cleanDate, isValidDate, onFormatDate } from "../../utilities/formatDates";
import { limitOptions } from "../../utilities/datesFilterConstants";
import { specialConnectors } from "../../utilities/connectorsConfiguration";

const useStyles = makeStyles({
  container: {
    padding: "10px",
  },
  dateRangeInput: {
    marginBottom: "10px",
    width: "100%",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  inputGroup: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  inputLabel: {
    margin: "5px!important",
    display: "inline-block",
  },
  inputSelect: {
    flex: "2",
  },
  deleteIcon: {
    color: tokens.colorPalettePurpleBackground2,
    cursor: "pointer",
    marginLeft: "5px",
  },
  numberSelect: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "5px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  metricsDelete: {
    float: "right",
  },
  groupName: {
    fontWeight: "bold!important",
    textTransform: "uppercase",
  },
  tagTruncatedPrimaryText: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
  },
});

const DataTableAccordion = ({
  datasource,
  filters,
  dimensions,
  sourceMetrics,
  selectedDimsOptions = [],
  selectedDimsColsOptions = [],
  selectedMetricsOptions = [],
  appliedFilters = [],
  appliedMetricsSorts = [],
  appliedDimsSorts = [],
  setSelectedDimsOptions,
  setSelectedDimsColsOptions,
  setSelectedMetricsOptions,
  setAppliedFilters,
  setAppliedMetricsSorts,
  setAppliedDimsSorts,
  onDatesChange,
  onCompareDatesChange,
  dates,
  setDates,
  limits,
  setLimits,
  reportType,
  previousReportType,
  setPreviousReportType,
}) => {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openSortMetrics, setOpenSortMetrics] = React.useState(false);
  const [openSortDims, setOpenSortDims] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);

  const [dims, setDims] = React.useState(dimensions || {});
  const [metrics, setMetrics] = React.useState(sourceMetrics || {});

  const [filterDims, setFilterDims] = React.useState("");
  const [filterMetrics, setFilterMetrics] = React.useState("");
  const [filterDimsColumns, setFilterDimsColumns] = React.useState("");
  const [isPickerDimsOpen, setIsPickerDimsOpen] = React.useState(false);
  const [isPickerMetricsOpen, setIsPickerMetricsOpen] = React.useState(false);
  const [isPickerDimsColsOpen, setIsPickerDimsColsOpen] = React.useState(false);
  const isSpecialConnector = specialConnectors.find((connector) => connector.key === datasource);
  // const combinedDims = [...selectedDimsOptions, ...selectedDimsColsOptions];
  const [selectedMetricsIds, setSelectedMetricsIds] = React.useState(selectedMetricsOptions || []);
  const [selectedDimsIds, setSelectedDimsIds] = React.useState(selectedDimsOptions || []);
  const [selectedDimsColsIds, setSelectedDimsColsIds] = React.useState(selectedDimsColsOptions || []);
  const [combinedDims, setCombinedDims] = React.useState([...selectedDimsOptions, ...selectedDimsColsOptions] || []);
  React.useEffect(() => {
    setSelectedDimsOptions(selectedDimsOptions);
    setSelectedDimsColsOptions(selectedDimsColsOptions);
    setCombinedDims([...selectedDimsOptions, ...selectedDimsColsOptions]);
  }, [selectedDimsOptions, selectedDimsColsOptions]);

  React.useEffect(() => {
    if (dimensions) setDims(dimensions);
    if (sourceMetrics) setMetrics(sourceMetrics);
  }, [dimensions, sourceMetrics]);

  React.useEffect(() => {
    if (reportType && previousReportType !== reportType) {
      setSelectedDimsOptions([]);
      setSelectedDimsColsOptions([]);
      setSelectedMetricsOptions([]);
      setPreviousReportType(reportType);
    }
  }, [reportType, previousReportType]);

  const tagPickerDimsOptions = React.useMemo(() => {
    return Object.entries(dims).reduce((acc, [group, items]) => {
      const filteredDimsItems = items.filter((item) => {
        const matchesFilterDims = filterDims ? item.name.toLowerCase().includes(filterDims.toLowerCase()) : true;
        const matchesFilterDimsColumns = filterDimsColumns
          ? item.name.toLowerCase().includes(filterDimsColumns.toLowerCase())
          : true;
        return (
          matchesFilterDims &&
          matchesFilterDimsColumns &&
          // !selectedDimsIds.includes(item.name) &&
          !selectedDimsIds.some((dim) => dim.id === item.id) &&
          !selectedDimsColsIds.some((dim) => dim.id === item.id)
          // !selectedDimsColsIds.includes(item.name)
        );
      });

      if (filteredDimsItems.length > 0) {
        acc.push({ group, items: filteredDimsItems });
      }

      return acc;
    }, []);
  }, [dims, filterDims, filterDimsColumns, selectedDimsIds, selectedDimsColsIds]);

  const initialTagPickerMetricsOptions = React.useMemo(() => {
    return Object.entries(metrics).reduce((acc, [group, items]) => {
      const filteredMetricsItems = items.filter((item) => {
        const matchesFilter = filterMetrics ? item.name.toLowerCase().includes(filterMetrics.toLowerCase()) : true;
        const isNotSelected = !selectedMetricsIds.includes(item.name);

        return matchesFilter && isNotSelected;
      });

      if (filteredMetricsItems.length > 0) {
        acc.push({ group, items: filteredMetricsItems });
      }

      return acc;
    }, []);
  }, [metrics, filterMetrics, selectedMetricsIds]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateSelection = (dates) => {
    const formattedDates = {
      start_date: isValidDate(dates.start_date) ? new Date(dates.start_date) : dates.start_date,
      end_date: isValidDate(dates.end_date) ? new Date(dates.end_date) : dates.end_date,
      date_range_type: dates.date_range_type,
    };
    setDates(formattedDates);
    onDatesChange(formattedDates);
  };

  const handleApply = (dates) => {
    const formattedDates = {
      start_date: isValidDate(dates.start_date) ? new Date(dates.start_date) : dates.start_date,
      end_date: isValidDate(dates.end_date) ? new Date(dates.end_date) : dates.end_date,
      date_range_type: dates.date_range_type,
    };
    setDates(formattedDates);
    setOpen(false);
  };

  const handleDiscard = () => {
    setOpen(false);
    setDates((prevDates) => ({
      ...prevDates,
    }));
  };

  const handleApplyFilters = (filters) => {
    setAppliedFilters(filters);
    setOpenFilters(false);
  };

  const handleApplySorts = (sorts) => {
    setAppliedMetricsSorts(sorts);
    setOpenSortMetrics(false);
  };

  const handleApplyDimsSorts = (sorts) => {
    setAppliedDimsSorts(sorts);
    setOpenSortDims(false);
  };

  return (
    <>
      <DateRangeDialog
        open={open}
        handleClose={handleClose}
        handleApply={handleApply}
        handleCompareDates={onCompareDatesChange}
        handleDiscard={handleDiscard}
        onDateSelect={handleDateSelection}
        selectedDates={dates}
      />
      <SortMetricsDialog
        open={openSortMetrics}
        handleClose={() => setOpenSortMetrics(false)}
        handleApply={handleApplySorts}
        handleDiscard={() => setOpenSortMetrics(false)}
        selectedMetrics={selectedMetricsOptions}
        initialSorts={appliedMetricsSorts}
      />
      <SortDimsDialog
        open={openSortDims}
        handleClose={() => setOpenSortDims(false)}
        handleApply={handleApplyDimsSorts}
        handleDiscard={() => setOpenSortDims(false)}
        selectedDims={combinedDims}
        initialSorts={appliedDimsSorts}
      />
      <FiltersDialog
        open={openFilters}
        handleClose={handleClose}
        handleApply={handleApplyFilters}
        handleDiscard={() => setOpenFilters(false)}
        dimensions={dims}
        filtersList={filters}
        initialFilters={appliedFilters}
      />

      {datasource ? (
        <div id="selects-main-container" className={styles.container}>
          {!isSpecialConnector && (
            <Button onClick={() => setOpen(true)} className={styles.dateRangeInput}>
              <Body1>
                {dates &&
                  `${isValidDate(dates.start_date) ? onFormatDate(dates.start_date) : cleanDate(dates.start_date)} - ${isValidDate(dates.end_date) ? onFormatDate(dates.end_date) : cleanDate(dates.end_date)}`}
              </Body1>
            </Button>
          )}

          <div className={styles.buttonGroup}>
            <Button onClick={() => setOpenSortMetrics(true)} size="large" className="flex flex-col">
              <Caption1Strong>Sort metrics</Caption1Strong>
              <Caption1>{appliedMetricsSorts.length}</Caption1>
            </Button>
            <Button onClick={() => setOpenSortDims(true)} size="large" className="flex flex-col">
              <Caption1Strong>Sort dims</Caption1Strong>
              <Caption1>{appliedDimsSorts.length}</Caption1>
            </Button>
            <Button onClick={() => setOpenFilters(true)} size="large" className="flex flex-col">
              <Caption1Strong>Filters</Caption1Strong>
              <Caption1>{appliedFilters.length}</Caption1>
            </Button>
          </div>

          <div className="mb-2 relative" id="metric-container">
            <Caption1 className={styles.inputLabel}>Select metrics</Caption1>
            <Button
              onClick={() => {
                setSelectedMetricsOptions([]);
                setSelectedMetricsIds([]);
              }}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
          </div>
          <Field>
            <TagPicker
              className="w-full"
              inline={true}
              positioning={{
                position: "below",
                align: "bottom",
                pinned: true,
                disableUpdateOnResize: true,
              }}
              open={isPickerMetricsOpen}
              onOpenChange={(e, data) => {
                const isClosing = data.type === "blur";
                setIsPickerMetricsOpen(!isClosing);
                if (isClosing) {
                  const selectedFullOptions = selectedMetricsOptions.map((selectedId) => {
                    return Object.values(metrics)
                      .flatMap((group) => group)
                      .find((item) => item.id === selectedId.id);
                  });
                  setSelectedMetricsOptions(selectedFullOptions);
                }
              }}
              onOptionSelect={(e, data) => {
                const selectedFullOptions = data.selectedOptions.map((selectedId) => {
                  return Object.values(metrics)
                    .flatMap((group) => group)
                    .find((item) => item.id === selectedId.id);
                });
                setSelectedMetricsOptions(selectedFullOptions);
                setSelectedMetricsIds(selectedFullOptions);
                setFilterMetrics("");
              }}
              selectedOptions={selectedMetricsIds}
            >
              <TagPickerControl as="div">
                <TagPickerGroup>
                  {selectedMetricsIds.map((option) => (
                    <Tag
                      primaryText={{
                        className: styles.tagTruncatedPrimaryText,
                      }}
                      key={option.id}
                      shape="rounded"
                      value={option}
                    >
                      {option.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput aria-label="Select Metrics" onChange={(e) => setFilterMetrics(e.target.value)} />
              </TagPickerControl>
              <TagPickerList disableAutoFocus="true">
                {initialTagPickerMetricsOptions.length > 0
                  ? initialTagPickerMetricsOptions.map(({ group, items }, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <Label
                          id="metric-label"
                          className={styles.groupName}
                          onClick={() => {
                            setSelectedMetricsOptions((prevOptions) => [...prevOptions, ...items]);
                            setSelectedMetricsIds((prevIds) => [
                              ...prevIds,
                              ...items.filter((item) => !prevIds.some((met) => met.id === item.id)),
                            ]);
                          }}
                        >
                          {group}
                        </Label>
                        {items
                          .filter((item) => !selectedMetricsIds.some((metric) => metric.id === item.id))
                          .map((item, itemIndex) => (
                            <TagPickerOption secondaryContent="" value={item} key={`${groupIndex}-${itemIndex}`}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                      </React.Fragment>
                    ))
                  : "No options available"}
              </TagPickerList>
            </TagPicker>
          </Field>
          <div className="mt-3 mb-2 relative" id="dims-rows-container">
            <Caption1 className={styles.inputLabel}>Select dims in rows</Caption1>
            <Button
              onClick={() => {
                setSelectedDimsIds([]);
                setSelectedDimsOptions([]);
              }}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
          </div>
          <Field>
            <TagPicker
              className="w-full"
              open={isPickerDimsOpen}
              onOpenChange={(e, data) => {
                const isClosing = data.type === "blur";
                setIsPickerDimsOpen(!isClosing);

                if (isClosing) {
                  const selectedFullOptions = selectedDimsOptions.map((selectedId) => {
                    return Object.values(dims)
                      .flatMap((group) => group)
                      .find((item) => item.id === selectedId.id);
                  });
                  setSelectedDimsOptions(selectedFullOptions);
                }
              }}
              inline={true}
              positioning={{ position: "below", align: "bottom", pinned: true, disableUpdateOnResize: true }}
              onOptionSelect={(e, data) => {
                const selectedFullOptions = data.selectedOptions.map((selectedId) => {
                  return Object.values(dims)
                    .flatMap((group) => group)
                    .find((item) => item.id === selectedId.id);
                });
                setSelectedDimsOptions(selectedFullOptions);
                setSelectedDimsIds(selectedFullOptions);
                setFilterDims("");
              }}
              selectedOptions={selectedDimsIds}
            >
              <TagPickerControl>
                <TagPickerGroup>
                  {selectedDimsIds.map((option) => (
                    <Tag
                      primaryText={{
                        className: styles.tagTruncatedPrimaryText,
                      }}
                      key={option.id}
                      shape="rounded"
                      value={option}
                    >
                      {option.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput aria-label="Select Dimensions" onChange={(e) => setFilterDims(e.target.value)} />
              </TagPickerControl>
              <TagPickerList>
                {tagPickerDimsOptions.length > 0
                  ? tagPickerDimsOptions.map(({ group, items }, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <Label
                          id="dim-row-label"
                          className={styles.groupName}
                          onClick={() => {
                            setSelectedDimsOptions((prevOptions) => [...prevOptions, ...items]);
                            setSelectedDimsIds((prevIds) => [
                              ...prevIds,
                              ...items.filter((item) => !prevIds.some((dim) => dim.id === item.id)),
                            ]);
                          }}
                        >
                          {group}
                        </Label>
                        {items
                          .filter((item) => !selectedDimsIds.some((dim) => dim.id === item.id))
                          .map((item, itemIndex) => (
                            <TagPickerOption secondaryContent="" value={item} key={`${groupIndex}-${itemIndex}`}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                      </React.Fragment>
                    ))
                  : "No options available"}
              </TagPickerList>
            </TagPicker>
          </Field>

          <div className={styles.numberSelect}>
            <label htmlFor="selectRows"># of rows</label>
            <Select
              id="selectRows"
              value={limits.limitRows}
              onChange={(e) => {
                setLimits({
                  limitRows: parseInt(e.target.value),
                  limitCols: limits.limitCols,
                });
              }}
            >
              {limitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>

          <div className="mt-3 mb-2 relative" id="dims-columns-container">
            <Caption1 className={styles.inputLabel}>Select dims in cols</Caption1>
            <Button
              onClick={() => {
                setSelectedDimsColsOptions([]);
                setSelectedDimsColsIds([]);
              }}
              className={styles.metricsDelete}
              appearance="transparent"
              icon={<Delete16Filled />}
            />
          </div>

          <Field>
            <TagPicker
              className="w-full"
              open={isPickerDimsColsOpen}
              onOpenChange={(e, data) => {
                const isClosing = data.type === "blur";
                setIsPickerDimsColsOpen(!isClosing);

                if (isClosing) {
                  const selectedFullOptions = selectedDimsColsOptions.map((selectedId) => {
                    return Object.values(dims)
                      .flatMap((group) => group)
                      .find((item) => item.id === selectedId.id);
                  });
                  setSelectedDimsColsOptions(selectedFullOptions);
                }
              }}
              inline={true}
              positioning={{ position: "below", align: "bottom", pinned: true, disableUpdateOnResize: true }}
              onOptionSelect={(e, data) => {
                const selectedFullOptions = data.selectedOptions.map((selectedId) => {
                  return Object.values(dims)
                    .flatMap((group) => group)
                    .find((item) => item.id === selectedId.id);
                });
                setSelectedDimsColsOptions(selectedFullOptions);
                setSelectedDimsColsIds(selectedFullOptions);
                setFilterDimsColumns("");
              }}
              selectedOptions={selectedDimsColsIds}
            >
              <TagPickerControl>
                <TagPickerGroup>
                  {selectedDimsColsIds.map((option) => (
                    <Tag
                      primaryText={{
                        className: styles.tagTruncatedPrimaryText,
                      }}
                      key={option.id}
                      shape="rounded"
                      value={option}
                    >
                      {option.name}
                    </Tag>
                  ))}
                </TagPickerGroup>
                <TagPickerInput aria-label="Select Dimensions" onChange={(e) => setFilterDimsColumns(e.target.value)} />
              </TagPickerControl>
              <TagPickerList>
                {tagPickerDimsOptions.length > 0
                  ? tagPickerDimsOptions.map(({ group, items }, groupIndex) => (
                      <React.Fragment key={groupIndex}>
                        <Label
                          id="dim-row-label"
                          className={styles.groupName}
                          onClick={() => {
                            setSelectedDimsColsOptions((prevOptions) => [...prevOptions, ...items]);
                            setSelectedDimsColsIds((prevIds) => [
                              ...prevIds,
                              ...items.filter((item) => !prevIds.some((dim) => dim.id === item.id)),
                            ]);
                          }}
                        >
                          {group}
                        </Label>
                        {items
                          .filter((item) => !selectedDimsColsIds.some((dim) => dim.id === item.id))
                          .map((item, itemIndex) => (
                            <TagPickerOption secondaryContent="" value={item} key={`${groupIndex}-${itemIndex}`}>
                              {item.name}
                            </TagPickerOption>
                          ))}
                      </React.Fragment>
                    ))
                  : "No options available"}
              </TagPickerList>
            </TagPicker>
          </Field>

          <div className={styles.numberSelect}>
            <label htmlFor="selectCols"># of cols</label>
            <Select
              id="selectCols"
              value={limits.limitCols}
              onChange={(e) => {
                setLimits({
                  limitRows: limits.limitRows,
                  limitCols: parseInt(e.target.value),
                });
              }}
            >
              {limitOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(DataTableAccordion);
