// Constants.js

const dev = {
  API_URL: `https://excel-test.dataslayer.ai`,
  EXCEL_DATASLAYER_URL: "https://test-excel-addin.dataslayer.ai",
  DATASLAYER_URL: "https://test.dataslayer.ai/",
  CLIENT_ID: "2bb6338d-6340-4165-a17e-6d24bab35836",
  M_AUTHORITY: "https://login.microsoftonline.com/common/oauth2/v2.0",
  SERVICE_ID: "10",
  CODE_CHALLENGE: "QvFvV7IP6JTQd4st7WGrmHkYY0aICXggdGtKSb1da3M",
  CODE_CHALLENGE_METHOD: "S256",
  REDIRECT_URI: "https://excel-test.dataslayer.ai/microsoft/auth/callback",
  RESPONSE_TYPE: "code",
  SCOPES: ["user.read", "email", "openid", "offline_access"],
  ONE_DRIVE_SCOPES: ["files.readwrite.all", "offline_access"],
  PROMPT: "login",
};

const prod = {
  API_URL: `https://excel.dataslayer.ai`,
  EXCEL_DATASLAYER_URL: "https://excel-addin.dataslayer.ai",
  DATASLAYER_URL: "http://app.dataslayer.ai/",
  CLIENT_ID: "2bb6338d-6340-4165-a17e-6d24bab35836",
  M_AUTHORITY: "https://login.microsoftonline.com/common/oauth2/v2.0",
  SERVICE_ID: "10",
  CODE_CHALLENGE: "QvFvV7IP6JTQd4st7WGrmHkYY0aICXggdGtKSb1da3M",
  CODE_CHALLENGE_METHOD: "S256",
  REDIRECT_URI: "https://excel.dataslayer.ai/microsoft/auth/callback",
  RESPONSE_TYPE: "code",
  SCOPES: ["user.read", "email", "openid", "offline_access"],
  ONE_DRIVE_SCOPES: ["files.readwrite.all", "offline_access"],
  PROMPT: "login",
};

export const config = prod;
