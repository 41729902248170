import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { FluentProvider } from "@fluentui/react-components";
import "./main.css";
import { lightTheme } from "./theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { DialogProvider } from "./context/DialogContext";
import { createHiddenSheet } from "../utilities/excel";
import { QueryProvider } from "./context/QueryContext";
import ErrorBoundary from "./components/ErrorBoundary";
import { MessageProvider } from "./context/MessageContext";

const queryClient = new QueryClient();
window.queryClient = queryClient;

const title = "Dataslayer Task Pane Add-in";
const rootElement = document.getElementById("container");
const root = createRoot(rootElement);
const initializeRibbonEvents = () => {
  if (!Office.actions || !Office.actions.associate) {
    setTimeout(initializeRibbonEvents, 100);
    return;
  }

  const ribbonActions = {
    manageQueriesAction: "manageQueries",
    refreshAllQueriesAction: "refreshAllQueries",
    refreshActiveSheetAction: "refreshActiveSheet",
    duplicateFileAction: "duplicateFile",
    templateGalleryAction: "templateGallery",
    gettingStartedAction: "gettingStarted",
    refreshAndEmailAction: "refreshAndEmail",
  };

  Object.entries(ribbonActions).forEach(([actionKey, actionValue]) => {
    Office.actions.associate(actionKey, (event) => {
      const ribbonEvent = new CustomEvent("ribbonButtonClick", { detail: { action: actionValue } });
      window.dispatchEvent(ribbonEvent);
      event.completed();
    });
  });
};

/* Render application after Office initializes */
Office.onReady(async () => {
  await Excel.run(async (context) => {
    await createHiddenSheet(context);
  });

  root.render(
    <QueryClientProvider client={queryClient}>
      <FluentProvider theme={lightTheme}>
        <MessageProvider>
          <DialogProvider>
            <QueryProvider>
              <ErrorBoundary>
                <App title={title} />
              </ErrorBoundary>
            </QueryProvider>
          </DialogProvider>
        </MessageProvider>
      </FluentProvider>
    </QueryClientProvider>
  );

  initializeRibbonEvents();
});

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
