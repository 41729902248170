export const connectorsWithAccounts = [
  "ads",
  "amazon_ads",
  "applesearchads",
  "analytics_v4",
  "bing",
  "bigquery",
  "criteo",
  "dcm",
  "dbm",
  "googlemybusiness",
  "mailchimp",
  "facebook",
  "facebookinsights",
  "google_ads",
  "google_analytics",
  "searchconsole",
  "youtube",
  "linkedin",
  "instagram",
  "twitter",
  "stripe",
  "tiktok",
  "linkedinpages",
  "snapchat",
  "pinterest",
  "tiktok_insights",
  "twitter_insights",
  "twitter_organic",
  "search_ads_360",
  "woocommerce",
  "klaviyo",
  "hubspot_basic",
  "reddit",
];

export const connectorsWithoutAccounts = [
  "json_csv_xml",
  "google_trends",
  "facebook_public_data",
  "instagram_public_data",
  "google_sheets",
];

export const specialConnectors = [
  { key: "google_trends", name: "Google Trends" },
  { key: "google_sheets", name: "Google Sheets" },
  { key: "facebook_public_data", name: "Facebook Public Data" },
  { key: "instagram_public_data", name: "Instagram Public Data" },
  { key: "json_csv_xml", name: "JSON/CSV/XML" },
  { key: "database", name: "Database" },
  { key: "mongodb", name: "MongoDB" },
  { key: "bigquery", name: "BigQuery" },
];

export const loginOptionConnectors = [
  { key: "json_csv_xml", name: "json_options" },
  { key: "database", name: "database_options" },
];

export const connectorConfigurations = {
  facebook_public_data: {
    hasAccountsSection: false,
    accordions: {
      reportConfiguration: {
        sections: [
          {
            id: "queryType",
            name: "Select query type",
            type: "select",
            values: [
              { id: "page", name: "Page data" },
              { id: "page_posts", name: "Posts by a page" },
            ],
          },
          {
            id: "page_ids",
            name: "Page aliases/IDs",
            type: "textarea",
            label: "Page aliases/IDs",
            visibleOn: "page",
          },
          {
            id: "page_ids",
            name: "Page aliases/IDs",
            type: "textarea",
            label: "Page aliases/IDs",
            visibleOn: "page_posts",
          },
          {
            id: "number_of_posts",
            name: "# of posts per page",
            type: "select",
            label: "# of posts per page",
            visibleOn: "page_posts",
            values: [
              { id: "5", name: "5" },
              { id: "10", name: "10" },
              { id: "50", name: "50" },
              { id: "100", name: "100" },
              { id: "250", name: "250" },
            ],
          },
        ],
      },
      dataTable: {
        isDateRange: false,
      },
    },
  },
  google_sheets: {
    hasAccountsSection: false,
    accordions: {
      dataTable: {
        sections: [
          {
            id: "document_url",
            name: "URL",
            type: "input",
            label: "URL",
            text: true,
          },
          {
            id: "sheet_name",
            name: "Sheet name",
            type: "input",
            label: "Sheet name",
            text: true,
          },
          {
            id: "sheet_range",
            name: "Sheet range",
            type: "input",
            label: "Sheet range",
            text: true,
          },
        ],
      },
    },
  },
  database: {
    hasAccountsSection: false,
    accordions: {
      dataTable: {
        sections: [
          {
            id: "query",
            name: "query",
            type: "codemirror",
            label: "Query SQL",
            placeholder: "Enter your SQL query",
            mode: "sql",
          },
          {
            id: "databaseTables",
            name: "table",
            type: "tables",
            visibleOn: "database",
            values: [],
          },
          {
            id: "databaseColumns",
            name: "table",
            type: "columns",
            visibleOn: "tableSelected",
            values: [],
          },
        ],
      },
    },
  },
  mongodb: {
    hasAccountsSection: false,
    accordions: {
      dataTable: {
        sections: [
          {
            title: "MongoDB Configuration",
            fields: [
              {
                type: "textarea",
                name: "mongodbQuery",
                label: "Query",
                placeholder: "Enter your MongoDB query",
                rows: 4,
                cols: 50,
                mode: "sql",
              },
              {
                type: "select",
                name: "queryType",
                label: "Query type",
                options: [
                  { id: "aggregation", name: "Aggregation" },
                  { id: "find", name: "Find" },
                ],
              },
              {
                type: "select",
                name: "collection",
                label: "Collection",
                options: [],
              },
            ],
          },
        ],
      },
    },
  },
  json_csv_xml: {
    hasAccountsSection: false,
    accordions: {
      dataTable: {
        sections: [
          {
            id: "url",
            name: "url",
            type: "input",
            label: "URL",
            placeholder: "Enter the URL",
            helpLink: {
              url: "https://dataslayer.ai/list-of-public-apis/",
              iconClass: "help-icon fa-solid fa-circle-question",
            },
          },
          {
            id: "type",
            name: "type",
            type: "select",
            label: "Data type",
            values: [
              { id: "json", name: "JSON" },
              { id: "csv", name: "CSV" },
              { id: "xml", name: "XML" },
            ],
          },
          {
            id: "request",
            name: "request",
            type: "input",
            label: "Request",
            placeholder: "Enter the request method (e.g., GET, POST)",
          },
          {
            id: "headers",
            name: "headers",
            type: "input",
            label: "HTTP headers",
            placeholder: "Enter the HTTP headers",
          },
          {
            id: "json_path",
            name: "json_path",
            type: "input",
            label: "JSON path",
            placeholder: "Enter the JSON path",
          },
        ],
      },
    },
  },
  instagram_public_data: {
    hasAccountsSection: false,
    accordions: {
      reportConfiguration: {
        sections: [
          {
            id: "queryType",
            name: "queryType",
            type: "select",
            values: [
              { id: "profile_info", name: "Profile info" },
              { id: "profile_posts", name: "Profile posts" },
              { id: "post_search", name: "Post search" },
            ],
          },
          {
            id: "usernames",
            name: "Instagram profiles",
            type: "textarea",
            label: "Instagram profiles",
            visibleOn: "profile_info",
          },
          {
            id: "usernames",
            name: "Instagram profiles",
            type: "textarea",
            label: "Instagram profiles",
            visibleOn: "profile_posts",
          },
          {
            id: "hashtags",
            name: "Instagram hashtags",
            type: "textarea",
            label: "Instagram hashtags",
            visibleOn: "post_search",
          },
          {
            id: "post_search_type",
            name: "Post search type",
            type: "select",
            label: "Post search type",
            visibleOn: "post_search",
            values: [
              { id: "recent_media", name: "Recent" },
              { id: "top_media", name: "Top" },
            ],
          },
          {
            id: "num_posts_by_hashtag",
            name: "# of posts per hashtag",
            type: "select",
            label: "# of posts per hashtag",
            visibleOn: "post_search",
            values: [
              { id: "50", name: "50" },
              { id: "100", name: "100" },
              { id: "150", name: "150" },
              { id: "200", name: "200" },
              { id: "250", name: "250" },
            ],
          },
        ],
      },
      dataTable: {
        isDateRange: false,
      },
    },
  },
  google_trends: {
    hasAccountsSection: false,
    accordions: {
      reportConfiguration: {
        sections: [
          {
            id: "queryType",
            name: "queryType",
            type: "select",
            values: [
              { id: "interest_over_time", name: "Interest over time", selected: true },
              { id: "interest_by_region", name: "Interest by region" },
              { id: "related_queries", name: "Related queries" },
              { id: "related_topics", name: "Related topics" },
              { id: "related_suggestions", name: "Topic suggestions" },
              { id: "daily_search_trends", name: "Daily search trends" },
              { id: "top_charts", name: "Top charts" },
            ],
          },
        ],
      },
    },
  },
  bigquery: {
    hasAccountsSection: true,
    accordions: {
      dataTable: {
        sections: [
          {
            id: "query",
            name: "query",
            type: "codemirror",
            label: "Query SQL",
            placeholder: "Enter your SQL query",
            mode: "sql",
          },
        ],
      },
    },
  },
};
