import * as React from "react";
import {
  makeStyles,
  Button,
  MessageBar,
  MessageBarBody,
  MessageBarTitle,
  Card,
  CardHeader,
  Caption1Stronger,
  Caption1,
  CardFooter,
  Tooltip,
} from "@fluentui/react-components";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeft16Filled, CloudSync20Regular, DeleteFilled, EditFilled } from "@fluentui/react-icons";
import { authorizeOneDrive, checkOneDrivePermissions, syncWithOneDrive } from "../../utilities/office-apis-helpers";
import { handleSchedule, showDialog } from "../../utilities/excel";
import { useMessage } from "../context/MessageContext";
import { deleteSchedule, getAllSchedules } from "../../services/actions";
import { config } from "../../Constants";
import { cleanDate, formatDateTime, onFormatDate } from "../../utilities/formatDates";
import { useQueryRefresh } from "../../utilities/useQueryRefresh";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    width: "auto",
    height: "auto",
    boxSizing: "border-box",
    padding: "15px",
    gap: "15px",
    "> *": {
      textOverflow: "ellipsis",
    },
    "> :not(:first-child)": {
      marginTop: "0px",
    },
    "> *:not(.ms-StackItem)": {
      flexShrink: 1,
    },
  },
  queryCard: {
    borderRadius: "10px",
    backgroundColor: "#e3e1e2",
    border: "1px solid rgba(0, 0, 0, .125)",
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
});

const ScheduleManager = ({ setSecret, setOneDriveAuth, refreshSchedules }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const { message, updateMessage } = useMessage();
  const [data, setData] = React.useState(null);
  const user_email = localStorage.getItem("DS-EXCEL-USERNAME");
  const { getSchedulesRefetch } = useQueryRefresh(null, null, null, user_email, updateMessage);

  React.useEffect(() => {
    const verifyPermissions = async () => {
      try {
        const result = await checkOneDrivePermissions();
        setIsAuthorized(!!result);
      } catch (error) {
        console.error("Error verifying permissions:", error);
      }
    };

    verifyPermissions();
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [refreshSchedules]);

  const fetchData = async () => {
    try {
      const fetchedData = await getSchedulesRefetch();
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching schedules:", error);
    }
  };

  const handleAuthorize = async () => {
    await authorizeOneDrive(setSecret);
    const result = await checkOneDrivePermissions();
    setIsAuthorized(!!result);
    setOneDriveAuth(true);
  };

  const handleSync = async () => {
    await syncWithOneDrive(updateMessage);
  };

  // Handlers for Edit and Delete actions
  const handleEdit = (schedule) => {
    handleSchedule(updateMessage, schedule, async () => {
      const updatedData = await getSchedulesRefetch();
      setData(updatedData);
    });
  };

  const handleDelete = async (scheduleId) => {
    try {
      await deleteSchedule(config.SERVICE_ID, user_email, scheduleId);
      const updatedData = await getSchedulesRefetch();
      setData(updatedData);
    } catch (error) {
      showDialog("Failed to delete schedule. Please try again.", []);
    }
  };

  return (
    <div className={styles.root}>
      <div id="back-to-queries">
        <Button
          className={styles.padding0}
          appearance="transparent"
          onClick={() => {
            navigate("/");
          }}
          icon={<ArrowLeft16Filled />}
        >
          Manage Queries
        </Button>
      </div>
      <div className="gap-3 flex flex-col">
        {!isAuthorized && (
          <>
            <MessageBar key="auth-warning" intent="warning" className="mb-3">
              <MessageBarBody>
                <MessageBarTitle className="block">Warning</MessageBarTitle>
                Before schedules can be registered, file synchronization with OneDrive is required. Please authorize
                access to OneDrive to enable this functionality
              </MessageBarBody>
            </MessageBar>
            <Button disabled={isAuthorized} onClick={handleAuthorize} className="w-full" appearance="primary">
              {isAuthorized ? "Access granted" : "Authorize OneDrive Access"}
            </Button>
          </>
        )}
        {isAuthorized && (
          <>
            <Button
              onClick={() =>
                handleSchedule(updateMessage, null, async () => {
                  const updatedData = await getSchedulesRefetch();
                  setData(updatedData);
                })
              } // Abre el diálogo de registro de schedules
              className="w-full"
              appearance="primary"
            >
              Register your schedules
            </Button>
            <Link onClick={handleSync} appearance="subtle" className="text-center">
              <CloudSync20Regular />
              Sync File to Onedrive
            </Link>
            {/* <Button onClick={handleSync} className="w-full" appearance="primary">
              Sincronizar
            </Button> */}
            {message && <p className="text-center">{message}</p>}

            {/* AQUI LISTA DE SCHEDULES */}
            {data?.data.schedules &&
              data.data.schedules.map((schedule) => (
                <Card className={styles.queryCard} key={schedule.schedule_id}>
                  <CardHeader
                    header={
                      <div className={styles.cardHeader}>
                        <Caption1Stronger>SCHEDULE</Caption1Stronger>
                      </div>
                    }
                  />
                  <div className="flex flex-col">
                    <Caption1 className={styles.textOverflow}>Type: {schedule.type}</Caption1>
                    <Caption1 className={styles.textOverflow}>Timezone: {schedule.timezone}</Caption1>
                    <Caption1 className={styles.textOverflow}>
                      Created: {cleanDate(formatDateTime(new Date(schedule.created), schedule.timezone, "yyyy-MM-dd"))}
                    </Caption1>
                    <Caption1 className={styles.textOverflow}>
                      Last execution: {schedule.last_execution_time && schedule.last_execution_time}
                    </Caption1>
                  </div>
                  <CardFooter className="flex justify-between">
                    <div>
                      <Tooltip content="Update schedule" relationship="label" positioning="below">
                        <Button
                          onClick={() => {
                            handleEdit(schedule);
                          }}
                          appearance="transparent"
                          icon={<EditFilled />}
                        />
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip content="Delete schedule" relationship="label" positioning="below">
                        <Button
                          appearance="transparent"
                          icon={<DeleteFilled />}
                          onClick={() => handleDelete(schedule.schedule_id)}
                        />
                      </Tooltip>
                    </div>
                  </CardFooter>
                </Card>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleManager;
